<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full">
    <body class="h-full">
    ```
  -->
  <div class="min-h-full">
    <Disclosure as="nav" class="border-b border-gray-200 bg-white" v-slot="{ open }">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex h-16 justify-between">
          <div class="flex">
            <div class="flex flex-shrink-0 items-center">
              <img class="block h-7 w-auto lg:hidden" src="/img/icon.svg" alt="Your Company" />
              <img class="hidden h-7 w-auto lg:block" src="/img/icon.svg" alt="Your Company" />
            </div>
            <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
              <router-link
                v-for="item in navigation" :key="item.routeName" :to="{ name: item.routeName }"
                :class="[routeIsCurrent({ name: item.routeName }) ? 'border-emerald-600 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium']"
                :aria-current="routeIsCurrent({ name: item.routeName }) ? 'page' : undefined"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
          <div class="hidden sm:ml-6 sm:flex sm:items-center">
            <button type="button"
              class="hidden relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2">
              <span class="absolute -inset-1.5" />
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>

            <OrgSelector class="hidden ml-3 md:block" />

            <!-- Profile dropdown (desktop) -->
            <Menu as="div" class="relative ml-6">
              <div>
                <MenuButton
                  class="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2">
                  <span class="absolute -inset-1.5" />
                  <span class="sr-only">Open user menu</span>
                  <img class="h-8 w-8 rounded-full" :src="user?.photoURL || ''" alt="" />
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                    <a :href="item.href" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">{{
                      item.name }}</a>
                  </MenuItem>
                  <MenuItem>
                    <a href="#" @click.prevent="signOut" :class="['block px-4 py-2 text-sm text-gray-700']">
                      Sign out
                    </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
          <div class="-mr-2 flex items-center sm:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2">
              <span class="absolute -inset-0.5" />
              <span class="sr-only">Open main menu</span>
              <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="sm:hidden" v-slot="{ close }">
        <div class="space-y-1 pb-3 pt-2">
          <DisclosureButton
            v-for="item in navigation" :key="item.routeName"
            :as="RouterLink" :to="{ name: item.routeName }"
            @click="close()"
            :class="[routeIsCurrent({ name: item.routeName }) ? 'border-emerald-500 bg-emerald-50 text-emerald-700' : 'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800', 'block border-l-4 py-2 pl-3 pr-4 text-base font-medium']"
            :aria-current="routeIsCurrent({ name: item.routeName }) ? 'page' : undefined">{{ item.name }}</DisclosureButton>
        </div>
        <div class="border-t border-gray-200 pb-3 pt-4">
          <div class="flex items-center px-4">
            <div class="flex-shrink-0">
              <img class="h-10 w-10 rounded-full" :src="user?.photoURL || ''" alt="" />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium text-gray-800">{{ user?.displayName }}</div>
              <div class="text-sm font-medium text-gray-500">{{ user?.email }}</div>
            </div>
            <button type="button"
              class="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2">
              <span class="absolute -inset-1.5" />
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-3 space-y-1">
            <DisclosureButton v-for="item in userNavigation" :key="item.name" as="a" :href="item.href"
              class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800">{{
                item.name }}</DisclosureButton>
            <DisclosureButton
              class="block px-4 py-2 cursor-pointer text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
              as="router-link" @click.prevent="signOut" to="#"
            >
              Sign out
            </DisclosureButton>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>

    <slot/>

    <!-- possible starter template for pages using this layout: -->
    <div v-if="false" class="py-10">
      <header>
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">Dashboard</h1>
        </div>
      </header>
      <main>
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <!-- Your content -->
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { onMounted, ref } from 'vue'
import { useRouter, RouterLink, type Router } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useSessionStore } from '@/stores/session'
import { routeNames, routeIsCurrent } from '@/router'
import OrgSelector from './OrgSelector.vue'

const router = ref<Router>()
const authStore = useAuthStore()
const user = ref(authStore.user)

onMounted(() => {
  router.value = useRouter()
  useSessionStore().refreshSession()
})

const signOut = () => {
  authStore.signOut().then(() => router.value?.push({ name: routeNames['teams.login'] }))
}

const navigation = [
  { name: 'Dashboard', routeName: routeNames['teams.dashboard'] },
  // { name: 'Recognition', href: '#', current: false },
  { name: 'Phishing Defense', routeName: routeNames['teams.phishing.home'] },
  { name: 'Keyring', routeName: routeNames['teams.keyring.home'] },
  { name: 'Code Security', routeName: routeNames['teams.codesecurity.home'], current: false },
]
const userNavigation = [
  { name: 'Suggest features', href: `https://twitter.com/intent/tweet?text=${encodeURIComponent('@encryptedjuice')}` },
  // { name: 'Settings', href: '#' },
]

document?.documentElement?.classList.add('h-full')
document?.body?.classList.add('h-full', 'bg-gray-50')
</script>
/**

phishingTargets
{

}

phishingSimulationCampaigns
{
  started:
  ended:

  targets: [
    [id]: {
      id:
      name:
      email:
    }
  ]

  attempts: [
    [targetId]: [{
      // what was sent to whom at when and what happend
      type: 'email'
      target: x
      clicked: 1
      compromises(d): []
      reported: 0
      events: []
    }]
  ]
  rules/strategy:
}


Customers:
 - org susceptibility chart
   - clicks, compromises
 - org awareness
  - correctly reported attempts
  -
*/
